<template>
    <BaseBanner class="gst-banner--detail ma-0">
        <template v-slot:gst-banner-content>
            <div class="gst-banner__gradient px-4 px-md-0">
                <v-flex d-flex flex-column justify-space-between class="content-max-width u-height-100">
                    <div class="text-right">
                        <slot name="favorite"></slot>
                    </div>
                    <div>
                        <h1 class="gst-banner__title d-inline-block mb-6">
                            <slot name="title">
                                <b>
                                    {{ title }}
                                </b>
                            </slot>
                        </h1>
                    </div>
                </v-flex>
            </div>
        </template>
    </BaseBanner>
</template>
<script>
    import BaseBanner from '@core/shared/components/misc/BaseBanner.vue';

    export default {
        name: 'DetailBanner',
        components: {
            BaseBanner
        },
        props: {
            title: {
                type: String,
                default: ''
            },
            gradient: {
                type: String,
                default: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)',
            },
            displayGradient: {
                type: Boolean,
                default: true
            },
        }
    };
</script>
<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-banner--detail {
    padding-top: 55px;
    background-color: theme-color( 'new-primary' );
    color: theme-color( 'white' );

    .gst-banner__gradient {
        height: 127px;
        background: linear-gradient( 180deg, rgba( 0, 0, 0, 0 ) 0%, #000000 100% );
    }

    .gst-banner__title {
        line-height: 30px;
        font-size: 30px;
        font-weight: 400;
        letter-spacing: -0.882px;
    }
}
</style>
