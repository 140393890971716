import { mapGetters } from 'vuex';
import {
    hasTicketAvailable as eventUtilsHasTicketAvailable
} from '@core/utils/eventUtils';

export default {
    computed: {
        ...mapGetters( {
            isPurchaseFlowEventTicketTypeDirect: 'appTenant/isPurchaseFlowEventTicketTypeDirect',

        } ),
        hasTicketAvailable( ) {
            return eventUtilsHasTicketAvailable( this.event );
        },
        hasTicketAvailableOrPurchaseFlowNotDirect( ) {
            return !this.isPurchaseFlowEventTicketTypeDirect || this.hasTicketAvailable;
        }
    }
};