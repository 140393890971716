<template>
    <div class="gst-banner">
        <slot name="gst-banner-content"></slot>
    </div>
</template>

<script>
    export default {
        name: 'BaseBanner'
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-banner {
    margin: theme-spacing( n3 );
}
</style>
