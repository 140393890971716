<template>
    <div class="gst-venue">
        <DataLoading v-if="loading" class="mt-5 mb-5 pt-5 pb-5" />
        <div v-else>
            <DetailBanner :title="item.name" />
            <div class="content-max-width content-spacing">
                <v-flex xs12 md9>
                    <h4>{{ $t('_common:terms.event_plural' ) }}</h4>
                    <EventsSearchBy :search="searchEvents" class="mt-2" />
                </v-flex>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import EntityNotFoundRedirect404 from '@core/mixins/EntityNotFoundRedirect404';
    import DetailBanner from '@tenant/app/components/misc/DetailBanner.vue';
    import EventsSearchBy from '@tenant/app/components/event/EventsSearchBy.vue';

    export default {
        name: 'TheVenue',
        components: {
            DetailBanner,
            DataLoading,
            EventsSearchBy
        },
        mixins:[
            EntityNotFoundRedirect404
        ],
        data( ) {
            return {
                loading: true
            };
        },
        computed: {
            ...mapState( {
                item:     state => state.venues.detail
            } ),
            itemId( ) {
                return this.$route.params.id;
            },
            searchEvents() {
                return {
                    venueId: this.itemId
                };
            },
            imageUrl( ) {
                return this.item.imageUrl || 'BANNERS.VENUE';
            }
        },
        methods: {
            ...mapActions( {
                loadOne                             : 'venues/one',
            } )
        },
        async mounted( ) {
            const res = await this.loadOne( this.itemId );

            if ( !res ) {
                this._goToNotFoundPage( );
                return;
            }

            this.loading = false;
        }
    };
</script>